<template>
  <v-card :loading="loading" flat>
      <v-tabs v-model="filesType">
        <v-tab>&gt;</v-tab>
        <v-tab href="#results">Lab Results</v-tab>
        <v-tab href="#products">Lab Products</v-tab>
      </v-tabs>
      <v-tabs-items v-model="filesType">
        <v-tab-item value="results">
          <ReSubmitResults></ReSubmitResults>
        </v-tab-item>
        <v-tab-item value="products">
          <ReSubmitProducts></ReSubmitProducts>
        </v-tab-item>
      </v-tabs-items>
  </v-card>
</template>

<script>
import dateFormatter from "@/lib/dateFormatter";
import ccrsApi from "@/lib/ccrs-api";
export default {
  name: "Resubmits.vue",
  components: {
    ReSubmitResults: () =>  import('./ReSubmitResults.vue'),
    ReSubmitProducts: () =>  import('./ReSubmitProducts.vue'),
  },
  data: () => ({
    loading: false,
    filesType: 'results',
    accessFile: null,
    fileData: [],
    submittedFiles: [],
  }),
  methods: {
    async loadData() {
      this.loading = true;
      let query = await ccrsApi.get('/v2/labNewFiles/fileName/submitted');
      this.submittedFiles = query.data;
      this.loading = false;
    },
    async downloadFile(fileName) {
      this.loadingDownload = true;
      let fileType = '';
      if (fileName.includes('labtest')) {
        fileType = 'labResults';
      } else {
        fileType = 'labProducts';
      }
      let query = await ccrsApi.get('/v1/' + fileType + '/fileName/downloadTest/' + fileName);
      this.fileData = query.data;
      this.loadingDownload = false;
    },
    setAccessFile(fileName) {
      this.accessFile = fileName;
    },
    formattedDate(dateString) {
      return dateFormatter.formatDate(dateString);
    },
  },
}
</script>

<style scoped>

</style>